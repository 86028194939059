import { HttpParams } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { isEmpty, isUndefined, uniqBy } from 'lodash';
import { map } from 'rxjs';
import { ListingBaseComponent } from 'src/app/shared/components/base/listing-base.component';
import { BENEFICIARIES, TRANSFERS } from 'src/app/shared/constants/apis-list';
import {
  DEFAULT_PAGE_INDEX,
  FILE_TYPE,
  NUMBER_RECORDS_PER_PAGE,
} from 'src/app/shared/constants/constant-list';
import { ApiResponseBodyInterface } from 'src/app/shared/interfaces/api-response.interface';
import { AppPartnersService } from 'src/app/shared/services/app-partners.service';
import {
  selectAllBeneficiaries,
  selectAllPartnersTransactions,
  selectBeneficiariesLoading,
  selectTransactionsLoading,
} from 'src/app/shared/stores/slices/AppPartners';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent extends ListingBaseComponent implements OnInit {
  activeId: string = '';
  activeTab: string = 'total';
  exportDetails: any;

  searchText: string = '';
  tableSearching: any = {};
  tableDataListItems: any[] = [];
  tableFilters: any[] = [];
  beneficiaries: any[] = [];

  params: HttpParams = new HttpParams();
  queryParams: Params = {};

  page: number = DEFAULT_PAGE_INDEX;
  pageLimit: number = NUMBER_RECORDS_PER_PAGE;
  totalNumberOfRecords = 0;
  totalNumberOfPages = 0;
  lastPage = 0;
  hasMore = false;
  loading = false;

  fileToExport?: string;

  fromDate?: Date | null = null;
  toDate?: Date | null = null;
  dateCriteria: any = '';

  constructor(
    injector: Injector,
    private store: Store,
    private partnerService: AppPartnersService
  ) {
    super(injector);

    this.params = this.params.set('limit', NUMBER_RECORDS_PER_PAGE);
    this.params = this.params.set('offset', DEFAULT_PAGE_INDEX);

    this.store
      .select(selectTransactionsLoading)
      .subscribe((resp) => (this.loading = resp));

    this.store
      .select(selectBeneficiariesLoading)
      .subscribe((resp) => (this.loading = resp));

    this.store
      .select(selectAllPartnersTransactions)
      .subscribe((resource: ApiResponseBodyInterface | null | undefined) => {
        if (resource?.results && Array.isArray(resource.results)) {
          this.tableDataListItems = [];

          this.tableDataListItems = resource?.results?.map((item) => ({
            ...item,
            loading: false,
            amount: item?.amount?.value,
            currency: item.amount?.currency,
            beneficiary:
              item?.receiver?.first_name + ' ' + item?.receiver?.last_name,
          }));

          const totalPages = Math.ceil(
            Number(resource?.count) / Number(this.pageLimit)
          );
          this.lastPage = totalPages;
          this.totalNumberOfPages = totalPages;
          this.totalNumberOfRecords = Number(resource?.count);
          this.hasMore = 0 <= totalPages - this.page;
        }
      });

    this.store
      .select(selectAllBeneficiaries)
      .subscribe((resource: ApiResponseBodyInterface | null | undefined) => {
        if (resource?.results && Array.isArray(resource.results)) {
          this.tableDataListItems = [];

          this.tableDataListItems = resource?.results?.map((item) => ({
            ...item,
            loading: false,
          }));

          const totalPages = Math.ceil(
            Number(resource?.count) / Number(this.pageLimit)
          );
          this.lastPage = totalPages;
          this.totalNumberOfPages = totalPages;
          this.totalNumberOfRecords = Number(resource?.count);
          this.hasMore = 0 <= totalPages - this.page;
        }
      });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe({
      next: (params) => {
        this.activeId =
          params && params?.action ? params.action : 'transactions';

        this.initData();
      },
    });
  }

  searchInput(ev: string) {
    this.searchText = ev;

    if (this.activeId === 'transactions') {
      this.initData();
    } else {
      this.getAllBeneficiaries();
    }

    this.page = 1;
  }

  onSelect(which: string, date: NgbDate) {
    this.fromDate = new Date(date.year, date.month - 1, date.day);
    this.toDate = new Date(date.year, date.month - 1, date.day, 23, 59, 59);
  }

  applyFilter() {
    this.page = 1;

    this.initData();
  }

  private initData() {
    if (this.tableFilters && this.tableFilters.length) {
      this.tableFilters = uniqBy(this.tableFilters, 'field');

      this.tableFilters.forEach((item, index) => {
        Object.entries(item).forEach(([key, value]) => {
          console.log('Item: ', item);
          console.log('key: ', key);
          console.log('value: ', value);
          if (typeof value !== 'undefined') {
            this.params = this.params.set(
              String(`fields[${index}].${key}`),
              String(value)
            );

            this.params = !isEmpty(item.value)
              ? this.params.set('page', 1)
              : this.params.set('page', Number(this.page));

            console.log('Params: ', this.params.toString());
          }
        });
      });
    }
    if (this.page > 1) {
      const OFFSET = 10 * this.page - 10;
      this.params = this.params.set('limit', this.pageLimit);
      this.params = this.params.set('offset', OFFSET);
    } else {
      this.params = this.params.set('limit', Number(this.pageLimit));
      this.params = this.params.set('offset', '');
    }

    if (this.fromDate !== null) {
      this.params = this.params.set(
        'datetime_created__gte',
        this.fromDate?.toISOString()!
      );
      this.params = this.params.set(
        'datetime_created__lte',
        this.toDate?.toISOString()!
      );
    } else {
      this.params = this.params.set('datetime_created__gte', '');
      this.params = this.params.set('datetime_created__lte', '');
    }

    this.params = !isEmpty(this.searchText)
      ? this.params.set('search', this.searchText)
      : this.params.set('search', '');

    this.partnerService.getAllPartnersTransactions(this.params);
  }

  private getAllBeneficiaries() {
    if (this.page > 1) {
      const OFFSET = 10 * this.page - 10;
      this.params = this.params.set('limit', this.pageLimit);
      this.params = this.params.set('offset', OFFSET);
    } else {
      this.params = this.params.set('limit', Number(this.pageLimit));
      this.params = this.params.set('offset', '');
    }

    this.params = !isEmpty(this.searchText)
      ? this.params.set('search', this.searchText)
      : this.params.set('search', '');

    this.partnerService.getAllBeneficiaries(this.params);
  }

  onPageChange(ev: number) {
    this.page = Number(ev);

    if (this.activeId === 'transactions') {
      this.initData();
    } else {
      this.getAllBeneficiaries();
    }
  }

  onPageLimitChange(event: number) {
    if (!isUndefined(event)) {
      this.pageLimit = Number(event);
      this.page = DEFAULT_PAGE_INDEX;

      if (this.activeId === 'transactions') {
        this.initData();
      } else {
        this.getAllBeneficiaries();
      }
    }
  }

  tabChange(id: string) {
    this.activeId = id;
    this.tableSearching = [];
    this.searchText = '';

    this.page = 1;

    if (this.activeId === 'transactions') {
      this.initData();
    } else {
      this.getAllBeneficiaries();
    }
  }

  exportItems() {
    // if (
    //   !this.checkAccessModule(
    //     this.FEATURES.PARTNERS.name,
    //     this.PERMISSION.EXPORT
    //   )
    // ) {
    //   this.toastrService.warning(
    //     'You do not have enough privilege to access this functionality',
    //     'Access Denied'
    //   );
    //   return;
    // }

    if (this.activeId === 'transactions') {
      this.fileToExport = TRANSFERS;
    } else {
      this.fileToExport = BENEFICIARIES;
    }

    this.dataService.downloadFile(
      'GET',
      this.fileToExport,
      FILE_TYPE.EXCEL_SHEET,
      'user_records.xlsx'
    );
  }

  clearFilter() {
    this.fromDate = null;
    this.toDate = null;

    this.initData();
  }

  onColumnFilterChange(event: any) {
    this.tableFilters = event;
    this.page = DEFAULT_PAGE_INDEX;

    this.initData();
  }
}
