import {
  Action,
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
  State,
  createAction,
  props,
} from '@ngrx/store';
import {
  Keys,
  localStorageSync,
  rehydrateApplicationState,
} from 'ngrx-store-localstorage';

import brandReducer, { BrandState } from './slices/Brands';
import complianceReducer, { ComplianceState } from './slices/compliance';
import permissionsReducer, { PermissionState } from './slices/Permissions';
import receivedTransactionReducer, {
  ReceivedTransactionState,
} from './slices/ReceivedTransaction';
import cashoutReducer, {
  CashoutTransactionState,
} from './slices/CashoutTransaction';
import revenueReducer, {
  RevenueTransactionState,
} from './slices/RevenueTransaction';
import sendersReducer, { SendersState } from './slices/Senders';
import appUsersReducer, { AppUsersState } from './slices/AppUsers';
import appUsersFeedbackReducer, {
  AppUsersFeedbacksState,
} from './slices/AppUsersFeedbacks';
import appReducer, { AppStates } from './slices/App';
import dashboardBMUsersVisitsReducer, {
  DashboardBMUsersVisitsState,
} from './slices/DashboardBMUsersVisits';
import unregisteredAppUsersReducer, {
  UnregisteredAppUsersState,
} from './slices/UnregisteredAppUsers';
import cmsUsersReducer, { CMSUsersState } from './slices/CMSUsers';
import tellersReducer, { TellersBMState } from './slices/TellersBM';
import remittancePartnersReducer, {
  RemittancePartnersState,
} from './slices/RemittancePartners';
import acceptedTransactionReducer, {
  AcceptedTransactionState,
} from './slices/AcceptedTransactions';
import merchantsReducer, { MerchantsState } from './slices/Merchants';
import KYCReducer, { EKYCState } from './slices/EKYC';
import partnersReducer, { AppPartnersState } from './slices/AppPartners';

const storageActionType = '@ngrx/store/storage';

export const storageAction = createAction(
  storageActionType,
  props<{ payload: string }>()
);

type PayloadAction = Action & {
  payload: unknown;
};
export interface AppState {
  brands: BrandState;
  compliances: ComplianceState;
  _permissions: PermissionState;
  receivedTransaction: ReceivedTransactionState;
  cashoutTransaction: CashoutTransactionState;
  revenueTransaction: RevenueTransactionState;
  senders: SendersState;
  app: AppStates;
  appUsers: AppUsersState;
  unregisteredAppUsers: UnregisteredAppUsersState;
  appUsersFeedbacks: AppUsersFeedbacksState;
  dashboardBMUsersVisits: DashboardBMUsersVisitsState;
  CMSUsers: CMSUsersState;
  tellers: TellersBMState;
  remittancePartners: RemittancePartnersState;
  acceptedTransaction: AcceptedTransactionState;
  merchants: MerchantsState;
  ekyc: EKYCState;
  partners: AppPartnersState;
}

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    return reducer(state, action);
  };
}

export const reducers: ActionReducerMap<AppState> = {
  brands: brandReducer,
  compliances: complianceReducer,
  _permissions: permissionsReducer,
  receivedTransaction: receivedTransactionReducer,
  cashoutTransaction: cashoutReducer,
  revenueTransaction: revenueReducer,
  senders: sendersReducer,
  app: appReducer,
  appUsers: appUsersReducer,
  unregisteredAppUsers: unregisteredAppUsersReducer,
  appUsersFeedbacks: appUsersFeedbackReducer,
  dashboardBMUsersVisits: dashboardBMUsersVisitsReducer,
  CMSUsers: cmsUsersReducer,
  tellers: tellersReducer,
  remittancePartners: remittancePartnersReducer,
  acceptedTransaction: acceptedTransactionReducer,
  merchants: merchantsReducer,
  ekyc: KYCReducer,
  partners: partnersReducer,
};

export const localStorageSyncReducer =
  (reducer: ActionReducer<any>): ActionReducer<any> =>
  (state: State<AppState>, action: Action): any => {
    const keys = Object.keys(reducers);

    const isPayloadAction = 'payload' in action;
    const payloadAction: PayloadAction = action as PayloadAction;

    if (
      action.type === storageActionType &&
      isPayloadAction &&
      keys.includes(payloadAction.payload as string)
    ) {
      const rehydratedState = rehydrateApplicationState(
        [payloadAction.payload] as Keys,
        localStorage,
        (k) => k,
        true
      );
      return { ...state, ...rehydratedState };
    }

    return localStorageSync({
      keys,
      rehydrate: true,
    })(reducer)(state, action);
  };

export const metaReducers: MetaReducer<any>[] = [localStorageSyncReducer];
