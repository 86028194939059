import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { SuspendTransactionPromptConfirmationComponent } from 'src/app/pages/suspensions/components/suspend-transaction-prompt-confirmation/suspend-transaction-prompt-confirmation.component';
import { ListingBaseComponent } from 'src/app/shared/components/base/listing-base.component';

@Component({
  selector: 'app-user-profile-transaction-details',
  templateUrl: './user-profile-transaction-details.component.html',
  styleUrls: ['./user-profile-transaction-details.component.scss'],
})
export class UserProfileTransactionDetailsComponent
  extends ListingBaseComponent
  implements OnInit
{
  details: any;
  constructor(
    injector: Injector,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      details: any;
    },
    public dialogRef: MatDialogRef<UserProfileTransactionDetailsComponent>
  ) {
    super(injector);

    this.details = data.details;
  }

  ngOnInit(): void {
    console.log('DETAILS: ', this.details);
  }

  onSuspendTransaction(transaction: any, isSuspend: boolean) {
    this.dialog.open(SuspendTransactionPromptConfirmationComponent, {
      data: {
        isSuspend: !isSuspend,
        transaction: transaction,
        suspend: 'transaction',
      },
      width: '500px',
    });
  }

  close() {
    this.dialogRef.close();
  }
}
