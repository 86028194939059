import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { CountToModule } from 'angular-count-to';
import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist';
import { SharedModule } from '../../shared/shared.module';
import { BrandManagerDashboardComponent } from './pages/brand-manager/brand-manager-dashboard.component';
import { AdminDashboardComponent } from './pages/admin/admin-dashboard.component';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { BrandManagerDashboardDateFilterComponent } from './pages/brand-manager/components/brand-manager-dashboard-date-filter/brand-manager-dashboard-date-filter.component';
import { BrandManagerDashboardUserDetailsComponent } from './pages/brand-manager/components/brand-manager-dashboard-user-details/brand-manager-dashboard-user-details.component';
import { PurpleDatePickerModule } from 'src/app/shared/components/purple-date-picker/purple-date-picker.module';
import { AppExportButtonModule } from 'src/app/shared/components/app-export-button/app-export-button.module';
import { PurplTableModule } from 'src/app/shared/components/purpl-table/purpl-table.module';
import { BreadcrumbModule } from '../../shared/components/breadcrumb/breadcrumb.module';
import { DashboardTabsComponent } from './components/dashboard-tabs/dashboard-tabs.component';
import { BrandManagerDashboardAllUsersComponent } from './pages/brand-manager/components/brand-manager-dashboard-all-users/brand-manager-dashboard-all-users.component';
import { PageLimiterModule } from 'src/app/shared/components/page-limiter/page-limiter.module';
import { MatDialogModule } from '@angular/material/dialog';
import { TransactionsAgGridComponent } from './pages/partners/components/transactions-ag-grid/transactions-ag-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { TransactionDetailComponent } from './pages/partners/components/transaction-detail/transaction-detail.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { TransactionDetailRendererComponent } from './pages/partners/renderers/transaction-detail-renderer/transaction-detail-renderer.component';
import { BeneficiaryRendererComponent } from './pages/partners/renderers/beneficiary-renderer/beneficiary-renderer.component';
import { BeneficiaryProfileComponent } from './pages/partners/components/beneficiary-profile/beneficiary-profile.component';
import { BeneficiariesAgGridComponent } from './pages/partners/components/beneficiaries-ag-grid/beneficiaries-ag-grid.component';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TransactionStatusRendererComponent } from './pages/partners/renderers/transaction-status-renderer/transaction-status-renderer.component';
import { TransactionStatusSearchComponent } from './pages/partners/renderers/transaction-status-search/transaction-status-search.component';

@NgModule({
  declarations: [
    DashboardComponent,
    BrandManagerDashboardComponent,
    AdminDashboardComponent,
    BrandManagerDashboardDateFilterComponent,
    BrandManagerDashboardUserDetailsComponent,
    DashboardTabsComponent,
    BrandManagerDashboardAllUsersComponent,
    PartnersComponent,
    TransactionsAgGridComponent,
    TransactionDetailComponent,
    TransactionDetailRendererComponent,
    BeneficiaryRendererComponent,
    BeneficiaryProfileComponent,
    BeneficiariesAgGridComponent,
    TransactionStatusRendererComponent,
    TransactionStatusSearchComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    NgbModule,
    CountToModule,
    SharedModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,
    NgbNavModule,
    PurpleDatePickerModule,
    AppExportButtonModule,
    PurplTableModule,
    BreadcrumbModule,
    PageLimiterModule,
    MatDialogModule,
    AgGridModule,
    MatTableModule,
    MatSelectModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
  ],
  exports: [DashboardTabsComponent],
})
export class DashboardModule {}
