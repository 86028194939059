import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-purpl-table-avatar',
  templateUrl: './purpl-table-avatar.component.html',
  styleUrls: ['./purpl-table-avatar.component.scss'],
})
export class PurplTableAvatarComponent implements OnInit {
  @Input() avatarSrc?: string;
  @Input() status_id?: string;
  @Input() compliance: any;
  @Input() did_jumio_and_failed?: boolean;
  @Input() bounced_jumio?: boolean;

  @Output() onPress: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
