<app-breadcrumb>
  <div class="pull-right w-75">
    <app-search-bar
      [value]="searchText"
      (onSearch)="searchInput($event)"
      placeholder="Search ..."
    ></app-search-bar>
  </div>
</app-breadcrumb>

<div class="container-fluid merchants">
  <div class="card">
    <div class="card-body compliance-card-body">
      <div
        ngbNav
        #nav="ngbNav"
        [activeId]="activeId"
        class="tab-coupon profile-tabs brand-manager-dashboard"
        (navChange)="tabChange($event.nextId)"
      >
        <ng-container ngbNavItem="transactions">
          <a ngbNavLink>Transactions </a>
          <ng-template ngbNavContent>
            <div class="col-12 p-0 mt-3 h-100 mb-2">
              <div class="col-6 d-flex p-0">
                <div class="input-group m-auto input-grp-p mr-3">
                  <input
                    class="datepicker-here form-control"
                    (dateSelect)="onSelect('from', $event)"
                    ngbDatepicker
                    #from="ngbDatepicker"
                    placeholder="Select date to filter"
                    (click)="from.toggle()"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn calendar"
                      (click)="from.toggle()"
                      type="button"
                    >
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>

                <div class="col-5 text-center">
                  <a
                    class="btn btn-primary text-capitalize"
                    (click)="applyFilter()"
                    >Apply</a
                  >
                  <a
                    class="btn btn-primary text-capitalize ml-1"
                    (click)="clearFilter()"
                    >Clear Filters</a
                  >
                </div>
              </div>
              <ng-container *ngTemplateOutlet="agGridTemplate"></ng-container>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem="beneficiaries">
          <a ngbNavLink>Beneficiaries </a>
          <ng-template ngbNavContent>
            <div class="col-12 p-0 mt-3">
              <ng-container
                *ngTemplateOutlet="agGridBeneficiaries"
              ></ng-container>
            </div>
          </ng-template>
        </ng-container>
      </div>
      <div [ngbNavOutlet]="nav"></div>
      <purpl-export-button
        (exportEv)="exportItems()"
        [title]="'Click to generate report'"
      ></purpl-export-button>
    </div>
  </div>
</div>

<!-- *ngIf="
checkAccessModule(
  FEATURES.TRANSACTION_MANAGEMENT_REVENUE.name,
  PERMISSION.EXPORT
)
" -->

<ng-template #agGridTemplate>
  <app-transactions-ag-grid
    [tableDataListItems]="tableDataListItems"
    [pageLimit]="pageLimit"
    [page]="page"
    [hasMore]="hasMore"
    [totalElements]="totalNumberOfRecords"
    [params]="params"
    (onPageChanged)="onPageChange($event)"
    (onPageLimitChanged)="onPageLimitChange($event)"
  ></app-transactions-ag-grid>
</ng-template>

<ng-template #agGridBeneficiaries>
  <app-beneficiaries-ag-grid
    [tableDataListItems]="tableDataListItems"
    [pageLimit]="pageLimit"
    [page]="page"
    [hasMore]="hasMore"
    [totalElements]="totalNumberOfRecords"
    [params]="params"
    (onPageChanged)="onPageChange($event)"
    (onPageLimitChanged)="onPageLimitChange($event)"
    (onColumnFilterChanged)="onColumnFilterChange($event)"
  ></app-beneficiaries-ag-grid>
</ng-template>
